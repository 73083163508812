import React, { useState } from "react";
import { Button, Form, Input } from "antd";
import useAuthenthication from "../../hooks/useAuthentication";

interface IAuthProps {
  email: string;
  password: string;
}

const LoginPage: React.FC = () => {
  const { isLoading, signInCall } = useAuthenthication();
  const [error, setError] = useState();

  const onFinish = async (values: IAuthProps) => {
    try {
      await signInCall(values);
    } catch (error: any) {
      setError(error);
    }
  };

  return (
    <div
      style={{
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        alignContent: "center",
      }}
    >
      {error && <div>{error}</div>}
      <Form
        name="basic"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        style={{ maxWidth: 600 }}
        initialValues={{ remember: true }}
        onFinish={onFinish}
        // onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <Form.Item
          label="E-mail"
          name="email"
          rules={[{ required: true, message: "Введите e-mail!" }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Пароль"
          name="password"
          rules={[{ required: true, message: "Введите пароль!" }]}
        >
          <Input.Password />
        </Form.Item>

        <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
          <Button type="primary" htmlType="submit" loading={isLoading}>
            Авторизация
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default LoginPage;

import { useState } from "react";
import { useDispatch } from "react-redux";
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
} from "firebase/auth";
import { clearUserData, setUser } from "../redux/UserSlice";
import { auth } from "../service/config";

interface IAuthProps {
  email: string;
  password: string;
}

const useAuthenthication = () => {
  const dispath = useDispatch();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const signInCall = async ({ email, password }: IAuthProps) => {
    setIsLoading(true);
    try {
      const { user } = await signInWithEmailAndPassword(auth, email, password);
      dispath(setUser(user));
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const signUpCall = async ({ email, password }: IAuthProps) => {
    setIsLoading(true);
    try {
      const { user } = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );
      dispath(setUser(user));
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const logoutCall = async () => {
    setIsLoading(true);
    console.log("logoud");
    try {
      await signOut(auth);
      dispath(clearUserData());
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  return { isLoading, signInCall, signUpCall, logoutCall };
};

export default useAuthenthication;

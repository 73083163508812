import { Input } from "antd";
import { IProductProps } from "./product.list";

interface IProductBaseInfoProps {
  product: IProductProps | undefined;
}

const ProductBaseInfo = ({ product }: IProductBaseInfoProps) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        width: "50%",
        backgroundColor: "#fff",
        padding: 32,
        borderRadius: 16,
        marginRight: 16,
      }}
    >
      <div style={{ marginBottom: 16 }}>
        <div style={{ marginBottom: 8 }}>Наименование:</div>
        <Input disabled={true} value={product?.title} />
      </div>

      <div style={{ marginBottom: 16 }}>
        <div style={{ marginBottom: 8 }}>Цена MSRP:</div>
        <Input disabled={true} value={product?.pricePart?.MSRP} />
      </div>

      <div style={{ marginBottom: 16 }}>
        <div style={{ marginBottom: 8 }}>В стоке:</div>
        <Input disabled={true} value={product?.inventory} />
      </div>

      <div style={{ marginBottom: 16 }}>
        <div style={{ marginBottom: 8 }}>Артикул:</div>
        <Input disabled={true} value={product?.article} />
      </div>
    </div>
  );
};

export default ProductBaseInfo;

import moment from "moment";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { Input, Table } from "antd";
import type { ColumnsType } from "antd/es/table";

import "moment/locale/ru";
moment.locale("ru");

interface IUserDataType {
  id: string;
  displayName: string;
  firmName: string;
  email: string;
  phone: string;
  disabled: boolean | any;
  createTimestamp: number;
}

const renderUserStatus = (disabled: boolean) => {
  return (
    <div
      style={{
        display: "inline-block",
        textTransform: "uppercase",
      }}
    >
      {!disabled ? (
        <div
          style={{
            background: "#55b468",
            color: "#fff",
            fontWeight: "500",
            borderRadius: 2,
            letterSpacing: 0.12,
            paddingTop: 2,
            paddingBottom: 2,
            paddingLeft: 8,
            paddingRight: 8,
            fontSize: 9,
          }}
        >
          Активен
        </div>
      ) : (
        <div
          style={{
            background: "#e60000",
            color: "#fff",
            fontWeight: "500",
            borderRadius: 2,
            letterSpacing: 0.12,
            paddingTop: 2,
            paddingBottom: 2,
            paddingLeft: 8,
            paddingRight: 8,
            fontSize: 9,
          }}
        >
          Отключён
        </div>
      )}
    </div>
  );
};

const columns: ColumnsType<IUserDataType> = [
  {
    title: "Регистрация",
    dataIndex: "createTimestamp",
    render: (createTimestamp) => (
      <div>{moment(createTimestamp).format("DD MMMM YYYY")}</div>
    ),
    responsive: ["md"],
    sorter: {
      compare: (a, b) => b.createTimestamp - a.createTimestamp,
      multiple: 2,
    },
  },
  {
    title: "Имя",
    dataIndex: "displayName",
    render: (displayName) => (
      <div
        style={{
          width: 150,
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
          overflow: "hidden",
        }}
      >
        {displayName}
      </div>
    ),
    key: "displayName",
    responsive: ["xs"],
  },
  {
    title: "Юр. Лицо",
    dataIndex: "firmName",
    render: (firmName) => (
      <div
        style={{
          width: 200,
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
          overflow: "hidden",
        }}
      >
        {firmName ? firmName : "Физ.Лицо"}
      </div>
    ),
    responsive: ["md"],
  },
  {
    title: "E-mail",
    dataIndex: "email",
    responsive: ["md"],
  },
  {
    title: "Тел.",
    dataIndex: "phone",
    responsive: ["md"],
  },
  {
    title: "Цена",
    dataIndex: "defaultDoc",
    responsive: ["md"],
  },
  {
    title: "Доступ",
    responsive: ["md"],
  },
  {
    title: "Статус",
    dataIndex: "disabled",
    render: (disabled) => renderUserStatus(disabled),
    responsive: ["md"],
    sorter: {
      compare: (a, b) => b.disabled - a.disabled,
      multiple: 1,
    },
  },
];

const UsersList: React.FC = () => {
  const navigate = useNavigate();
  const [data, setData] = useState<IUserDataType[]>();
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState<IUserDataType[]>();

  const searchUser = (e: any) => {
    const search = e.target.value;

    setUsers(
      data?.filter((person) => {
        return (
          person.displayName.toLowerCase().includes(search.toLowerCase()) ||
          person.firmName.toLowerCase().includes(search.toLowerCase()) ||
          person.email.toLowerCase().includes(search.toLowerCase())
        );
      })
    );
  };

  const fetchData = () => {
    setLoading(true);
    try {
      fetch(`https://api.novotech.systems/user/list`)
        .then((res) => res.json())
        .then((users) => {
          setData(users);
          setUsers(
            users.sort(
              (a: any, b: any) => b.createTimestamp - a.createTimestamp
            )
          );
          setLoading(false);
        });
    } catch (error) {
      alert(error);
      console.log(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <Input
        onChange={searchUser}
        style={{ marginBottom: 16 }}
        placeholder="Поиск пользователя: имя, фирма, email"
      />
      <Table
        onRow={(record) => ({
          onClick: () => navigate(`/user/${record.id}`),
        })}
        columns={columns}
        dataSource={users}
        rowKey={(record) => record.id}
        loading={loading}
        size="small"
      />
    </>
  );
};

export default UsersList;

import { baseURL, headers } from "./user.service";

class ProductService {
  async getProductDetailById(Id: string | undefined) {
    try {
      const product = await fetch(`${baseURL}/products/${Id}`, {
        headers: headers,
      });
      return product;
    } catch (error) {
      console.log(error);
      return error;
    }
  }

  async disableProduct(Id: string, status: boolean) {
    try {
      const response = await fetch(`${baseURL}/products/disable`, {
        headers: headers,
        method: "POST",
        body: JSON.stringify({ Id, isDisable: status }),
      });
      const data = await response.json();
      return data;
    } catch (error) {
      console.log(error);
    }
  }
}

export default new ProductService();

import moment from "moment";
import { Switch } from "antd";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import UserService from "../../service/user.service";
import "./user.css";

const UserPermissions = (props: any) => {
  const { user } = props;
  const { id } = useParams();

  const [permissions, setPermissions] = useState({
    price: false,
    stock: false,
    order: false,
    project: false,
  });

  useEffect(() => {
    setPermissions({
      price: user?.price,
      stock: user?.stock,
      order: user?.order,
      project: user?.project,
    });
  }, [user]);

  const updatePermissions = async () => {
    if (user) {
      await UserService.updateUserById({ id, permissions: permissions });
    }
  };

  useEffect(() => {
    updatePermissions();
    // eslint-disable-next-line
  }, [permissions]);

  return (
    <div className="user-permissions">
      <table className="permission-table">
        <tbody>
          <tr>
            <td>E-mail</td>
            <td>{user?.email}</td>
          </tr>
          <tr>
            <td>Телефон</td>
            <td>{user?.phone ? user.phone : "Не указан"}</td>
          </tr>
          <tr>
            <td>Регистрация</td>
            <td>{moment(user?.createTimestamp).format("DD MMMM YYYY")}</td>
          </tr>
          <tr>
            <td>Цена</td>
            <td>{user?.defaultDoc ? user.defaultDoc : "Нет"}</td>
          </tr>
          <tr>
            <td>Адрес</td>
            <td>{user?.address ? user.address : "Не указан"}</td>
          </tr>
          <tr>
            <td>Цены</td>
            <td>
              <Switch
                checked={permissions.price}
                onChange={() => {
                  setPermissions({ ...permissions, price: !permissions.price });
                }}
              />
            </td>
          </tr>
          <tr>
            <td>Сток</td>
            <td>
              <Switch
                checked={permissions.stock}
                onChange={() => {
                  setPermissions({ ...permissions, stock: !permissions.stock });
                }}
              />
            </td>
          </tr>
          <tr>
            <td>Заказы</td>
            <td>
              <Switch
                checked={permissions.order}
                onChange={() => {
                  setPermissions({ ...permissions, order: !permissions.order });
                }}
              />
            </td>
          </tr>
          <tr>
            <td>Проектное</td>
            <td>
              <Switch
                checked={permissions.project}
                onChange={() => {
                  setPermissions({
                    ...permissions,
                    project: !permissions.project,
                  });
                }}
              />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default UserPermissions;

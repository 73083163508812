import { useState } from "react";
import { Button, notification } from "antd";

import UserCreate, { UserResponse } from "../../components/user/user.create";
import UsersList from "../../components/users/users-list";

type NotificationType = "success" | "info" | "warning" | "error";

export const UsersPage = () => {
  const [api, contextHolder] = notification.useNotification();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const callNotification = (type: any) => {
    if (type._writeTime) {
      const openNotificationWithIcon = (type: NotificationType) => {
        api[type]({
          message: "Регистрация",
          description: `Пользователь успешно зарегистрирован`,
        });
      };
      openNotificationWithIcon("success");
    }

    if (type.code === UserResponse.IsRegistred) {
      const openNotificationWithIcon = (type: NotificationType) => {
        api[type]({
          message: "Регистрация",
          description: (
            <>
              Пользователь с таким Email -
              <br />
              уже зарегистрирован
            </>
          ),
        });
      };
      openNotificationWithIcon("error");
    }

    if (type.code === UserResponse.InvalidEmail) {
      const openNotificationWithIcon = (type: NotificationType) => {
        api[type]({
          message: "Регистрация",
          description: "Введите правильный Email - адрес",
        });
      };
      openNotificationWithIcon("error");
    }

    if (type.code === UserResponse.Invalid1C) {
      const openNotificationWithIcon = (type: NotificationType) => {
        api[type]({
          message: "Регистрация",
          description:
            "Недостаточно данных для регистрации в 1C. Возможно не создан договор или не указана цена.",
        });
      };
      openNotificationWithIcon("error");
    }
  };

  return (
    <div>
      {contextHolder}
      <div style={{ paddingBottom: 16 }}>
        <Button onClick={() => setIsModalOpen(true)}>
          Создать пользователя
        </Button>
      </div>
      <UserCreate
        isModalOpen={isModalOpen}
        closeModal={() => setIsModalOpen(false)}
        sendNotification={callNotification}
      />
      <UsersList />
    </div>
  );
};

import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";

import { doc, updateDoc } from "firebase/firestore";
import { firestoreDb, storage } from "../service/config";
import { IProductProps } from "../components/products/product.list";

const PRODUCTS = "products";

const productRef = (productId: string) => {
  return doc(firestoreDb, PRODUCTS, productId);
};

const updateDocument = (ref: any, data: any) => {
  return updateDoc(ref, data);
};

export const updateProductId = (productId: string, data: any) => {
  return updateDocument(productRef(productId), data);
};

export const uploadImageProduct = async (product: any, file: any) => {
  const storageRef = ref(storage, "products/" + file.name);
  const uploadTask = uploadBytesResumable(storageRef, file);

  try {
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log("Upload is " + progress + "% done");
        switch (snapshot.state) {
          case "paused":
            console.log("Upload is paused");
            break;
          case "running":
            console.log("Upload is running");
            break;
        }
      },
      (error) => {
        switch (error.code) {
          case "storage/unauthorized":
            break;
          case "storage/canceled":
            break;
          case "storage/unknown":
            break;
        }
      },
      async () => {
        const response = await getDownloadURL(uploadTask.snapshot.ref);
        product.photoURL = response;

        try {
          await updateProductId(product.id, product);
          //  await updatePhoto(data);

          alert("Успешно");
        } catch (e) {
          console.log(e);
        }
      }
    );
  } catch (e) {
    console.log(e);
  }
};

import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { IUser } from "../../models/user";
import UserService from "../../service/user.service";

import UserHeader from "../../components/user/user.header";
import UserPermissions from "../../components/user/user.permissions";
import UserAnalytics from "../../components/user/user.analytics";
import { UserBlocked } from "../../components/user/user.blocked";

const UserInfo = () => {
  const { id } = useParams();
  const [user, setUser] = useState<IUser>();

  const getUserInfo = async (id: any) => {
    try {
      const user = await UserService.getUserById(id);
      setUser(user);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getUserInfo(id);
  }, [id]);

  return (
    <div style={{ display: "flex", gap: "1rem" }}>
      <div
        className="user-details"
        style={{
          width: 360,
          padding: 24,
          borderRadius: 16,
          backgroundColor: "#fff",
        }}
      >
        <UserHeader
          id={user?.id}
          photoURL={user?.photoURL}
          name={user?.displayName}
          srl={user?.firmName}
          email={user?.email}
          disabled={user?.disabled}
        />
        <UserPermissions user={user} />
      </div>
      <div>
        {user?.disabled && <UserBlocked />}
        <div
          className="user-info"
          style={{ backgroundColor: "#fff", padding: 24, borderRadius: 16 }}
        >
          <UserAnalytics user={user} />
        </div>
      </div>
    </div>
  );
};

export default UserInfo;

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
} from "chart.js";
import { useEffect, useState } from "react";
import { Line } from "react-chartjs-2";
import UserService from "../../service/user.service";
import { formatInMdl } from "../../utils/curreny";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler
);

export const options = {
  responsive: true,
  bezierCurve: true,
  plugins: {
    legend: {
      display: false,
    },
    title: {
      display: true,
      text: "Аналитика пользователя",
    },
  },
};

const labels = [
  "Янв",
  "Фев",
  "Март",
  "Апр",
  "Май",
  "Июнь",
  "Июль",
  "Авг",
  "Сент",
  "Окт",
  "Ноя",
  "Дек",
];

const data = {
  labels,
  datasets: [
    {
      lineTension: 0,
      label: "",
      data: [],
      borderColor: "",
      fill: {},
    },
  ],
};

const UserAnalytics = ({ user }: any) => {
  const [analytics, setAnalytics] = useState(data);
  const [yearlyData, setYearlyData] = useState(0);
  const [yearlyOldData, setYearlyOldData] = useState(0);

  const fetchUserAnalytics = async () => {
    if (user) {
      const response = await UserService.getUserAnalyticsInfo(user.c1uid);
      const yearCurrent = new Date().getFullYear();
      const yearOld = yearCurrent - 1;

      calculateYearlyData(response);

      setAnalytics({
        labels,
        datasets: [
          {
            label: `${yearCurrent}`,
            data: response.yearly,
            borderColor: "#7F11E0",
            lineTension: 0.3,
            fill: false,
          },
          {
            label: `${yearOld}`,
            data: response.yearlyOld,
            borderColor: "#1677FF",
            lineTension: 0.3,
            fill: {
              target: "origin",
              above: "rgba(48, 131, 248, 0.5)",
            },
          },
        ],
      });
    }
  };

  const calculateYearlyData = (data: any) => {
    const yearly = data.yearly.reduce((x: number, y: number) => x + y);
    const yearlyOld = data.yearlyOld.reduce((x: number, y: number) => x + y);

    setYearlyOldData(yearlyOld);
    setYearlyData(yearly);
  };

  useEffect(() => {
    fetchUserAnalytics();
    // eslint-disable-next-line
  }, [user]);

  return (
    <>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div>Текущий год: {formatInMdl(yearlyData)}</div>
        <div>Прошлый год: {formatInMdl(yearlyOldData)}</div>
      </div>

      <Line
        id="myChart"
        options={options}
        data={analytics}
        width={750}
        height={350}
      />
    </>
  );
};

export default UserAnalytics;

import { useState } from "react";

import Avatar from "../UI/Avatar";
import { Button, MenuProps } from "antd";
import { DashOutlined } from "@ant-design/icons";
import { Dropdown, Popconfirm, notification } from "antd";
import UserService from "../../service/user.service";

interface IUserHeader {
  id: string | undefined;
  photoURL: string | undefined;
  name: string | undefined;
  email: string | undefined;
  srl: string | undefined;
  disabled: boolean | undefined;
}

type NotificationType = "success" | "info" | "warning" | "error";

const UserHeader = (props: IUserHeader) => {
  const [openReset, setOpenReset] = useState(false);
  const [openDisable, setOpenDisable] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [api, contextHolder] = notification.useNotification();

  const resetUserPassword = async () => {
    if (!props.email) return;

    setConfirmLoading(true);
    try {
      await UserService.restoreUserPassword(props?.email);
      const openNotificationWithIcon = (type: NotificationType) => {
        api[type]({
          message: `Сброс пароля`,
          description: `Пароль успешно сброшен для - ${props.name}`,
        });
      };
      setConfirmLoading(false);
      openNotificationWithIcon("success");
    } catch (error: any) {
      const openNotificationWithIcon = (type: NotificationType) => {
        api[type]({
          message: `${error?.name}`,
          description: `${error?.code}`,
        });
      };
      setConfirmLoading(false);
      openNotificationWithIcon("error");
    }
    setOpenReset(false);
  };

  const disableUserById = async () => {
    if (!props.id) return;
    setConfirmLoading(true);

    try {
      await UserService.disabledUserById({
        id: props.id,
        status: !props.disabled,
      });
      const openNotificationWithIcon = (type: NotificationType) => {
        api[type]({
          message: `${isDisabled} пользователя`,
          description: `${props.name} - успешно`,
        });
      };
      setConfirmLoading(false);
      openNotificationWithIcon("success");
      setTimeout(() => window.location.reload(), 2500);
    } catch (error: any) {
      const openNotificationWithIcon = (type: NotificationType) => {
        api[type]({
          message: `${error?.name}`,
          description: `${error?.code}`,
        });
      };
      setConfirmLoading(false);
      openNotificationWithIcon("error");
    }
    setOpenDisable(false);
  };

  const isDisabled = props.disabled ? "Разблокировать" : "Заблокировать";

  const items: MenuProps["items"] = [
    {
      key: "1",
      label: (
        <Popconfirm
          title="Сбросить пароль"
          description="Вы действительно хотите сбросить пароль ?"
          okText="Да"
          cancelText="Отмена"
          open={openReset}
          okButtonProps={{ loading: confirmLoading }}
          onConfirm={resetUserPassword}
          onCancel={() => setOpenReset(false)}
        >
          <span onClick={() => setOpenReset(true)}>Сбросить пароль</span>
        </Popconfirm>
      ),
    },
    {
      key: "2",
      danger: props.disabled ? false : true,
      label: (
        <Popconfirm
          title={`${isDisabled} пользователя`}
          description={`Вы действительно хотите ${isDisabled} пользователя?`}
          okText="Да"
          cancelText="Отмена"
          open={openDisable}
          okButtonProps={{ loading: confirmLoading }}
          onConfirm={disableUserById}
          onCancel={() => setOpenDisable(false)}
        >
          <span onClick={() => setOpenDisable(true)}>{isDisabled}</span>
        </Popconfirm>
      ),
    },
  ];

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        marginBottom: 20,
      }}
    >
      <Avatar url={props.photoURL} />
      <div style={{ alignContent: "center" }}>
        <div
          style={{
            fontSize: 15,
            color: "#132233",
            fontWeight: "500",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            overflow: "hidden",
          }}
        >
          {props.name}
        </div>
        <div
          style={{
            marginTop: 8,
            color: "#71808e",
            fontWeight: "500",
            width: 180,
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            overflow: "hidden",
          }}
        >
          {props.srl ? props.srl : "Физ.Лицо"}
        </div>
      </div>
      <div
        style={{
          backgroundColor: "#f6f8f9",
          padding: 4,
          borderRadius: "50%",
        }}
      >
        <Dropdown menu={{ items }}>
          <span onClick={(e) => e.preventDefault()}>
            <DashOutlined style={{ color: "#71808e" }} />
          </span>
        </Dropdown>
      </div>
      <>{contextHolder}</>
    </div>
  );
};

export default UserHeader;

import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { IProductProps } from "../../components/products/product.list";

import productService from "../../service/product.service";

import ProductBaseInfo from "../../components/products/product.base-info";
import ProductImageInfo from "../../components/products/product.image-info";

const ProductDetail = () => {
  const [product, setProduct] = useState<IProductProps>();
  const { id } = useParams();

  const getProductDetail = async () => {
    try {
      const response: any = await productService.getProductDetailById(id);
      const product = await response.json();
      setProduct(product);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getProductDetail();
  }, [id]);

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      {product && (
        <>
          <ProductBaseInfo product={product} />
          <ProductImageInfo product={product} />
        </>
      )}
    </div>
  );
};

export default ProductDetail;

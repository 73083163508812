import { IUserId } from "../models/user";

interface ICreateUserProps {
  uid: string;
  email: string;
}

export const headers = {
  "Content-type": "application/json",
};

export const baseURL = "https://api.novotech.systems";
// export const baseURL = "http://localhost:8080";

class UserServices {
  async getCustomers() {
    try {
      const response = await fetch(`${baseURL}/c1/customer-list`);
      const customers = await response.json();
      return customers;
    } catch (error: any) {
      console.log(error);
      return error;
    }
  }

  async getUserById(id: IUserId): Promise<any> {
    try {
      const response = await fetch(`${baseURL}/user/get/${id}`);
      const user = await response.json();
      return user;
    } catch (error: any) {
      console.log(error);
      return error;
    }
  }

  async updateUserById({ id, permissions }: any) {
    try {
      const response = await fetch(`${baseURL}/user/set`, {
        method: "POST",
        headers: { ...headers },
        body: JSON.stringify({ id, permissions }),
      });
      const user = await response.json();
      return user;
    } catch (error: any) {
      console.log(error);
      return error;
    }
  }

  async getUserAnalyticsInfo(c1uid: string) {
    try {
      const response = await fetch(`${baseURL}/user-info/analytics/${c1uid}`);
      const data = await response.json();
      return data;
    } catch (error: any) {
      console.log(error);
      return error;
    }
  }

  async restoreUserPassword(email: string | undefined) {
    try {
      const response = await fetch(`${baseURL}/user/reset-password`, {
        method: "POST",
        headers: { ...headers },
        body: JSON.stringify({ email }),
      });
      const data = await response.json();
      return data;
    } catch (error: any) {
      console.log(error);
      return error;
    }
  }

  async disabledUserById({ id, status }: any) {
    try {
      const response = await fetch(`${baseURL}/user/disable-user`, {
        method: "POST",
        headers: { ...headers },
        body: JSON.stringify({ uid: id, status }),
      });
      return await response.json();
    } catch (error: any) {
      console.log(error);
      return error;
    }
  }

  async createUser(props: ICreateUserProps) {
    try {
      const response = await fetch(`${baseURL}/user/create`, {
        method: "POST",
        headers: { ...headers },
        body: JSON.stringify(props),
      });
      return await response.json();
    } catch (error) {
      console.log(error);
      return error;
    }
  }
}

const UserService = new UserServices();

export default UserService;

interface IAvatarProps {
  url: string | undefined;
}

const Avatar = (props: IAvatarProps) => {
  return (
    <img
      src={props.url}
      alt="avatar"
      style={{
        objectFit: "fill",
        width: 64,
        height: 64,
        borderRadius: "50%",
        border: 0.5,
        borderColor: "#132233",
      }}
    />
  );
};

export default Avatar;

export const UserBlocked = () => {
  return (
    <div
      style={{
        backgroundColor: "#fff",
        borderRadius: 16,
        marginBottom: 16,
        padding: 20,
      }}
    >
      Пользователь заблокирован
    </div>
  );
};

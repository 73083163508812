import { Form, Input, Modal, Select } from "antd";
import { useEffect, useState } from "react";
import UserService from "../../service/user.service";

interface IUserCreateModal {
  isModalOpen: boolean;
  closeModal: () => void;
  sendNotification: (type: UserResponse) => void;
}

export enum UserResponse {
  IsRegistred = 1,
  Success = "_writeTime",
  InvalidEmail = 3,
  Invalid1C = 4,
}

const UserCreate = (props: IUserCreateModal) => {
  const { isModalOpen, closeModal } = props;
  const [form] = Form.useForm();

  const [customers, setCustomers] = useState<any[]>([]);
  const [confirmLoading, setConfirmLoading] = useState<boolean>(false);

  const getCustomersList = async () => {
    try {
      const data = await UserService.getCustomers();
      const list = data.map((user: any) => ({
        label: user.Description,
        value: user.Ref_Key,
      }));
      setCustomers(list);
    } catch (error) {
      console.log(error);
    }
  };

  const handleCancel = () => closeModal();

  const handleOk = () => {
    form
      .validateFields()
      .then((values) => createUser(values))
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
  };

  const createUser = async (values: any) => {
    setConfirmLoading(true);

    try {
      const res = await UserService.createUser(values);
      props.sendNotification(res);
      setConfirmLoading(false);
    } catch (error) {
      console.log(error);
      setConfirmLoading(false);
    }
  };

  useEffect(() => {
    getCustomersList();
  }, []);

  return (
    <Modal
      title="Регистрация пользователя"
      open={isModalOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      okText={"Зарегистрировать"}
      cancelText={"Отмена"}
      confirmLoading={confirmLoading}
    >
      <Form
        form={form}
        layout="vertical"
        name="form_in_modal"
        initialValues={{ type: "cloud" }}
      >
        <Form.Item
          name="email"
          label="E-mail"
          rules={[
            {
              required: true,
              message: "E-mail обязателен!",
            },
          ]}
        >
          <Input type="email" placeholder="example@example.com" />
        </Form.Item>

        <Form.Item
          name="uid"
          label="Пользователь в 1С"
          rules={[
            {
              required: true,
              message: "Пользователь в 1С обязателен!",
            },
          ]}
        >
          <Select
            showSearch
            optionFilterProp="children"
            filterOption={(input, option) =>
              option?.props.children
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0 ||
              option?.props.value.toLowerCase().indexOf(input.toLowerCase()) >=
                0
            }
            style={{ width: "100%" }}
          >
            {customers.map((user) => (
              <Select.Option key={user.value} value={user.value}>
                {user.label}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default UserCreate;

import React from "react";
import ReactDOM from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

import "./index.css";

import AppPage from "./App";
import ErrorPage from "./error-page";

import store from "./redux/store";

import { Dashbord } from "./pages/dashbord";
import { UsersPage } from "./pages/users/users";
import UserInfo from "./pages/users/user.info";
import ProductPage from "./pages/product/product";
import ProductDetail from "./pages/product/product.detail";

import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { persistStore } from "redux-persist";

import AuthGuard from "./guards/AuthGuard";
import GuestGuard from "./guards/GuestGuard";
import LoginPage from "./pages/auth/login";

const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <AuthGuard>
        <AppPage />
      </AuthGuard>
    ),
    errorElement: <ErrorPage />,
    children: [
      {
        path: "/",
        element: <Dashbord />,
      },
      {
        path: "/users",
        element: <UsersPage />,
      },
      {
        path: "/user/:id",
        element: <UserInfo />,
      },
      {
        path: "/product/list",
        element: <ProductPage />,
      },
      {
        path: "/product/:id",
        element: <ProductDetail />,
      },
    ],
  },
  {
    path: "/sign-in",
    element: (
      <GuestGuard>
        <LoginPage />
      </GuestGuard>
    ),
  },
]);

const persistor = persistStore(store);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <RouterProvider router={router} />
      </PersistGate>
    </Provider>
  </React.StrictMode>
);

import { IProductProps } from "./product.list";

import { Button, UploadFile, UploadProps } from "antd";
import { Image, message, Upload } from "antd";
import { InboxOutlined } from "@ant-design/icons";

import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import { uploadImageProduct } from "../../firebase/storage";
import { useState } from "react";
import { RcFile } from "antd/es/upload";

const { Dragger } = Upload;

const props: UploadProps = {
  name: "file",
  multiple: false,

  onChange(info) {
    const { status } = info.file;
    if (status !== "uploading") {
      console.log(info.file, info.fileList);
    }
    if (status === "done") {
      message.success(`${info.file.name} file uploaded successfully.`);
    } else if (status === "error") {
      message.error(`${info.file.name} file upload failed.`);
    }
  },
  onDrop(e) {
    console.log("Dropped files", e.dataTransfer.files);
  },
};

interface IProductImageInfoProps {
  product: IProductProps | undefined;
}

const ProductImageInfo = ({ product }: IProductImageInfoProps) => {
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [uploading, setUploading] = useState(false);

  const handleUpload = () => {
    const file = fileList[0];
    setUploading(true);

    uploadImageProduct(product, file);

    setUploading(false);
  };

  const props: UploadProps = {
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
    beforeUpload: (file) => {
      setFileList([...fileList, file]);

      return false;
    },
    fileList,
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        width: "50%",
        backgroundColor: "#FFF",
        padding: 32,
        borderRadius: 16,
        marginRight: 16,
      }}
    >
      <Image width={250} src={product?.photoURL} alt="Предпросмотр" />

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          marginTop: 20,
        }}
      >
        <Upload {...props}>
          <Button icon={<InboxOutlined />}>Выбрать фотографию</Button>
        </Upload>
        <Button
          type="primary"
          onClick={handleUpload}
          disabled={fileList.length === 0}
          loading={uploading}
          style={{ marginTop: 16 }}
        >
          {uploading ? "Загрузка ..." : "Начать загрузку"}
        </Button>
      </div>
    </div>
  );
};

export default ProductImageInfo;

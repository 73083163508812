import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

export const firebaseConfig = {
  apiKey: "AIzaSyDUx6uf0UQB3X3pEkWmUghAMrjT99xM5QQ",
  authDomain: "novotech-admin.firebaseapp.com",
  projectId: "novotech-admin",
  storageBucket: "novotech-admin.appspot.com",
  messagingSenderId: "267896255405",
  appId: "1:267896255405:web:e55e19d87dc38dda056724",
};

const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);
export const firestoreDb = getFirestore();
export const storage = getStorage();

export default app;

import React, { useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { Button, Layout, Menu, Space } from "antd";

import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  DashboardOutlined,
  UserOutlined,
  DatabaseOutlined,
} from "@ant-design/icons";
import useAuthenthication from "./hooks/useAuthentication";
import { useSelector } from "react-redux";

const { Header, Sider, Content } = Layout;

const navigation = [
  { label: "Показатели", key: "/", icon: <DashboardOutlined /> },
  { label: "Пользователи", key: "/users", icon: <UserOutlined /> },
  { label: "Товары", key: "/product/list", icon: <DatabaseOutlined /> },
];

const AppPage: React.FC = () => {
  const user = useSelector(({ UserSlice }: any) => UserSlice.user);
  const navigate = useNavigate();
  const { logoutCall } = useAuthenthication();
  const [collapsed, setCollapsed] = useState(false);

  const signOutUser = async () => await logoutCall();

  const handleMenuClick = ({ key }: any) => {
    if (key) {
      navigate(key);
    }
  };

  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Sider trigger={null} collapsible collapsed={collapsed}>
        <div className="logo" />
        <Menu
          theme="dark"
          mode="inline"
          items={navigation}
          onClick={handleMenuClick}
        />
      </Sider>
      <Layout className="site-layout">
        <Header style={{ paddingLeft: 16, background: "#fff" }}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Space>
              {collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
            </Space>
            <Button onClick={signOutUser}>Выйти - {user?.email}</Button>
          </div>
        </Header>
        <Content
          style={{
            margin: 24,
            padding: 24,
            minHeight: 280,
            // background: "#fff",
          }}
        >
          <Outlet />
        </Content>
      </Layout>
    </Layout>
  );
};

export default AppPage;

import algoliasearch from "algoliasearch";
import { Button, Image, Space, Table } from "antd";
import { ColumnsType } from "antd/es/table";
import {
  connectHits,
  InstantSearch,
  SearchBox,
  Stats,
} from "react-instantsearch-dom";
import "./product.css";
import productService from "../../service/product.service";

const searchClient = algoliasearch(
  "5NY1GU9QBT",
  "687844230a9f36a1dcd29e6422ff8534"
);

export interface IProductProps {
  article: string;
  category: string;
  category_key: string;
  description: string;
  id: string;
  inventory: number;
  link: string;
  objectID: string;
  parent_key: string;
  photoURL: string;
  product_key: string;
  search_key: string;
  title: string;
  disable: boolean;
  pricePart?: {
    A: number;
    A1: number;
    A2: number;
    A3: number;
    A4: number;
    B: number;
    B1: number;
    B2: number;
    B3: number;
    B4: number;
    C: number;
    C1: number;
    C2: number;
    C3: number;
    C4: number;
    D: number;
    D1: number;
    D2: number;
    D3: number;
    D4: number;
    DDP: number;
    E: number;
    E1: number;
    E2: number;
    E3: number;
    E4: number;
    MSRP: number;
    RETAIL: number;
    S: number;
  };
}

const disableProductById = async (Id: string, status: boolean) => {
  try {
    await productService.disableProduct(Id, status);
    setTimeout(() => {
      window.location.reload();
    }, 1000);
  } catch (error) {
    console.log(error);
  }
};

const columns: ColumnsType<IProductProps> = [
  {
    title: "Артикул",
    dataIndex: "article",
  },
  {
    title: "Изображение",
    dataIndex: "photoURL",
    render: (_, p) => (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Image src={p.photoURL} width={50} />
      </div>
    ),
  },
  {
    title: "Наименование",
    dataIndex: "title",
  },
  {
    title: "Описание",
    dataIndex: "description",
    render: (_, i) => (
      <div
        style={{
          minWidth: 100,
          maxWidth: 400,
          height: 20,
          textOverflow: "ellipsis",
          overflow: "hidden",
        }}
      >
        {i.description}
      </div>
    ),
  },
  {
    title: "Кол-во",
    dataIndex: "inventory",
  },
  {
    title: "Действия",
    render: (_, p) => (
      <Space>
        <a href={`/product/${p.objectID}`}>Детали</a>
        <Button
          size="small"
          onClick={() => disableProductById(p.article, p.disable)}
          danger={p.disable}
        >
          {p.disable ? "Включить" : "Отключить"}
        </Button>
      </Space>
    ),
  },
];

const Hits = ({ hits }: any) => (
  <div className="card">
    {hits && (
      <Table
        dataSource={hits}
        columns={columns}
        size={"small"}
        rowKey={(i: IProductProps) => i.id}
      />
    )}
  </div>
);

const CustomHits = connectHits(Hits);

const ProductList = () => {
  return (
    <InstantSearch searchClient={searchClient} indexName="prod_list">
      <SearchBox
        className="ant-input"
        translations={{ placeholder: "Поиск товаров" }}
      />
      <Stats />
      <CustomHits />
    </InstantSearch>
  );
};

export default ProductList;
